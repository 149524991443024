@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .no-scrollbars::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbars {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .card-shadow {
    background: linear-gradient(180deg, #eaf1ff 0%, #fefefe 100%);
    border-color: rgba(234, 240, 255, 1);
    box-shadow: 0 6px 16px 1px rgba(188, 187, 232, 0.33);
  }

  .card-hover {
    color: white;
    border-radius: 8px;
  }

  .card-hover > * {
    color: white;
  }
}

.content-div {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.content-div:hover {
  background-image: linear-gradient(to right, rgba(126, 213, 111, 0.801), hsla(160, 64%, 43%, 0.801)),
    url("https://images.unsplash.com/photo-1522093007474-d86e9bf7ba6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=400&q=80");
}

.image-cover {
  height: 260px;
}

/*
-remove the classes below if you have the 'group-hover'property added in your tailwind config file
-also remove the class from the html template
*/
.content-div:hover .fd-cl {
  opacity: 0.25;
}

.content-div:hover .fd-sh {
  opacity: 1;
}

@layer components {
  .standard-container {
    @apply py-8 px-6 sm:px-8 md:px-8 lg:px-16;
  }
}

.glass {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.25);
  border-radius: 16px 16px 64px 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.5px);
  -webkit-backdrop-filter: blur(4.5px);
  border: 4px solid rgba(255, 255, 255, 0.1);
}

/* Add margin for resolution above 1600px */
@media (min-width: 1600px) {
  .app {
    padding-left: 15%;
    padding-right: 15%;
  }
}

html {
  scroll-behavior: smooth !important;
  font-family: poppins, sans-serif;
}
