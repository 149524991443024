#privacy-table > div {
  display: grid;
  grid-template-columns: repeat(9, minmax(0, 1fr));
}

#privacy-table > * {
  line-height: 2;
}

#privacy-table > p,
span,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
  margin: 2rem 0;
}

/* Apply style to 1st child of all div children of id=privacy-table */
#privacy-table > div > :first-child {
  grid-column: span 3 / span 3;
  margin-top: 4rem;
  padding: 0 1rem 0 0;
}

#privacy-table > div > :first-child > h2 {
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 600;
}

#privacy-table > span {
  font-weight: 600;
}

#privacy-table > div > :nth-child(2) {
  grid-column: span 6 / span 6;
  margin-top: 4rem;
}

#privacy-table > div > :nth-child(2) > h2 {
  font-weight: 600;
}

#privacy-table > ul {
  margin: 1.5rem 0;
}

#privacy-table > li {
  list-style-type: disc;
  margin-left: 1rem;
}

#privacy-table > a > span {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity));
  font-weight: 500;
}

/* Add white space to above span */
#privacy-table > a > span::before {
  content: " ";
}

/* Add white space to below span */
#privacy-table > a > span::after {
  content: " ";
}

#privacy-table > h1 {
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 600;
}
